import React, { useEffect, useState } from "react";
import { Form, Input, Button, Checkbox, message } from "antd";
import AxiosCmsController, { cmsImageUrl } from "../../api/apiControllerCms";
import { useParams } from "react-router-dom";
const Privacy = () => {
  const [form] = Form.useForm();
  const { id } = useParams();

  const [data, setData] = useState(null);
  const [maindata, setmainData] = useState(null);
  const [loading, setLoading] = useState(true);


  const handleFetch = () => {
    setLoading(true)
    AxiosCmsController.get("/api/contact-us?populate[ContactUsItems][populate]=icon")
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching about us data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const handleFetchPrivaacy = () => {
    setLoading(true)
    AxiosCmsController.get("/api/sozlesmelers/" + id)
      .then((response) => {
        setmainData(response.data);
        console.log(response.data)
      })
      .catch((error) => {
        console.error("Error fetching about us data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    handleFetch()
  }, []);

  useEffect(() => {
    if (id) {
      handleFetchPrivaacy()
    }
  }, [id])


  const onFinish = (values) => {
    console.log("Form values:", values);
    message.success("Form başarıyla gönderildi!");
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    message.error("Form gönderilemedi.");
  };

  return (
    <div className="lg:container  lg:mx-auto">
      <div className="flex flex-col mt-[24px] w-full gap-12 lg:flex-col">
      
        <div className="flex flex-col   w-full">
          <h1>{maindata?.title}</h1>
          <div
            className="text-[34px] w-full rounded-[24px]"
            dangerouslySetInnerHTML={{
              __html: maindata?.content
            }}
          ></div>
        </div>
        <div className="flex flex-col lg:flex-row lg:justify-around">
          {data?.ContactUsItems?.map((item, index) => (
            <div key={index} className="flex flex-col gap-4 lg:gap-0 items-center">
              {/* bg-[#FFEB00] */}
              <div className="flex items-center justify-center w-[80px] h-[80px]  rounded-[20px]">
                <img
                  src={cmsImageUrl + item?.icon?.url}
                  alt={item?.icon?.name}
                  className="lg:w-auto lg:h-auto"
                />
              </div>
              <div className="flex flex-col gap-0 items-center">
                <h4 className="font-semibold text-[#09090B] text-[20px]">
                  {item?.Value}
                </h4>
                <span className="text-sm text-[#52525B] max-w-[280px] text-center leading-loose">
                  {item?.Desc}
                </span>
              </div>
            </div>

          ))}
        </div>
      </div>
    </div>
  );
};

export default Privacy;
