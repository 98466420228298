import React, { useState } from 'react'
import { Link } from 'react-router-dom';

const ReceivedTab = () => {
  const [cars, setCars] = useState([]);
  return (
    <div>
      {!cars.length ?
        <div className='flex flex-col justify-center items-center min-h-[400px]'>
          <img src="/assets/icons/car-solid-standart.svg" alt="car-icon" />
          <span className="mt-[28px] text-[18px] font-medium text-[#09090B]">Henüz hiç bir araç almadınız</span>
          <span className='mt-[12px] text-sm text-[#71717A]'>Lorem ipsum dolor</span>
        </div>
        :
        <div>
          <ul className="mt-[16px] ">
            {cars.map((car, id) => (
              <li key={id} className="">
                <Link to={`/received/${car.id}`} className="flex flex-col lg:flex-row gap-[24px] items-center ">
                  <img
                    src={`/assets/img/${car.image}.png`}
                    alt={`${car.name} Fotoğraf`}
                    className="w-[380px] h-[218px]"
                  />
                  <div className="w-full">
                    <div className="flex justify-between items-center w-full">
                      <div className="flex flex-col">
                        <h2 className="font-semibold text-[#09090B] text-lg">
                          {car.name}
                        </h2>
                        <h2 className="mt-[4px] mb-[10px] lg:mt-[8px] lg:mb-[20px] text-sm font-light text-[#09090B]">
                          {car.carPack}
                        </h2>
                      </div>
                      <div className='flex flex-col self-start w-44 lg:w-auto'>
                        <h2 className='text-right text-sm font-light text-[#71717A]'>Alış Fiyatı</h2>
                        <div className='font-semibold text-right text-sm lg:text-[20px] text-[#09090B] mt-[14px] lg:mt-[8px] lg:mb-[20px]'>{car.buyingPrice} TL</div>
                      </div>
                    </div>
                    <div className="flex gap-2.5 lg:gap-5 items-center">
                          <div className="flex items-center gap-2">
                            {/* <img src="/assets/icons/fuel.svg" alt="oil-icon" /> */}
                            <span className="text-sm text-[#71717A]">
                              {car.mileage}
                            </span>
                          </div>
                          <div className="flex items-center gap-2">
                            <img
                              src="/assets/icons/fuel.svg"
                              alt="oil-icon"
                            />
                            <span className="text-sm text-[#71717A]">
                              {car.fuel}
                            </span>
                          </div>
                          <div className="flex items-center gap-2">
                            <img
                              src="/assets/icons/gearbox.svg"
                              alt="gearbox-icon"
                            />
                            <span className="text-sm text-[#71717A]">
                              {car.transmission}
                            </span>
                          </div>
                          <div className="flex items-center gap-2">
                            <img
                              src="/assets/icons/calendar-colourless.svg"
                              alt="calendar-icon"
                            />
                            <span className="text-sm text-[#71717A]">
                              {car.model}
                            </span>
                          </div>
                        </div>
                    <div className="border-2 dashed-border w-full h-px my-[20px]"></div>
                    <div className="flex flex-col lg:flex-row lg:justify-between lg:items-end">
                      <div className="flex flex-col">
                        <div className="flex items-center gap-2.5">
                          <span className="font-light text-sm text-[#71717A]">
                            Satış Tarihi: 4 Eylül 2024 Çarşamba
                          </span>
                        </div>
                      </div>
                      <div className="flex items-center gap-2 mt-3 lg:mt-0">
                        <span className="bg-[#FFEB00] py-[12px] text-center lg:py-[6px] px-[12px] rounded-[8px] text-[#09090B] w-full lg:w-auto font-semibold text-sm">
                          Teslimat Bekleniyor
                        </span>
                      </div>
                    </div>
                  </div>
                </Link>
                <div className="w-full h-px bg-gray-200 my-5"></div>
              </li>
            ))}
          </ul>
        </div>
      }
    </div>
  )
}

export default ReceivedTab