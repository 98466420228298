import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

// Import required modules
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import { Spin, message, Image, Modal } from "antd";
import AxiosController, { imageBaseUrl } from "../../api/apiController";
import CarStatusView from "../../components/CarStatusView/CarStatusView"; // Default export
import FeatureGroupView from "../../components/CarStatusView/FeatureGroupView"; // Named export
import AuctionBidAdd from "../../components/auction/AuctionBidAdd";
import AuctionBidLast from "../../components/auction/AuctionBidLast";
import { formatCurrency } from "../../utils/FormatCurrency";
import AxiosCmsController, { cmsImageUrl } from "../../api/apiControllerCms";


const AuctionsDetail = () => {
  const { id } = useParams();
  const [detailCar, setDetailCar] = useState(null);
  const [loading, setLoading] = useState(false);
  const [valorContent, setValorContent] = useState(null);
  const [featuresId, setFeaturesId] = useState([]);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [initialSlideIndex, setInitialSlideIndex] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);
  const openModal = (index) => {
    setInitialSlideIndex(index);
    setIsModalOpen(true);
  };

  // Modali kapatmak için fonksiyon
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Timer kurarak currentTime'ı güncelle
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000); // Her saniye güncellenir

    return () => clearInterval(timer); // Bileşen unmount olduğunda temizlenir
  }, []);

  // API'den açık artırma detaylarını çekme
  useEffect(() => {
    const fetchAuctionDetail = async () => {
      setLoading(true);
      try {
        const response = await AxiosController.get(`/v1/auction/car/auctions/${id}`);
        if (response.data && response.data) {
          setDetailCar(response.data);
          const images = response.data?.car?.images || [];
          if (images.length > 0) {
            setSelectedImage(images[0]);
          }
          if (response.data?.car?.car_feature_selections && response.data?.car?.car_feature_selections.length > 0) {
            setFeaturesId(response.data?.car?.car_feature_selections.map(xx => xx?.feature_item_id));
          }
        } else {
          message.error("Açık artırma detayları alınamadı.");
        }
      } catch (error) {
        message.error("Açık artırma detayları getirilirken bir hata oluştu.");
        console.error("Açık artırma detayları getirilirken hata oluştu:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAuctionDetail();
  }, [id]);

  useEffect(() => {
    const fetchValorBanner = async () => {
      setLoading(true)
      try {
        const response = await AxiosCmsController.get('/api/valor', {
          params: {
            populate: 'valorimage'
          }
        })
        const valorData = response.data?.valorimage;
        console.log("Valor Image Data:", valorData);
        console.log("resp", response.data)
        setValorContent(valorData)
        console.log("first", valorContent)
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false);
      }
    }
    fetchValorBanner()
  }, [])



  const formatTime = (milliseconds) => {
    if (milliseconds <= 0) {
      return "0sn";
    }

    let totalSeconds = Math.floor(milliseconds / 1000);
    const days = Math.floor(totalSeconds / (3600 * 24));
    totalSeconds %= 3600 * 24;
    const hours = Math.floor(totalSeconds / 3600);
    totalSeconds %= 3600;
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;

    const parts = [];
    if (days > 0) parts.push(`${days}g`);
    if (hours > 0) parts.push(`${hours}s`);
    if (minutes > 0) parts.push(`${minutes}dk`);
    if (seconds > 0 || parts.length === 0) parts.push(`${seconds}sn`);

    return parts.join(" ");
  };


  if (loading || !detailCar) {
    return (
      <div className="flex justify-center items-center my-10">
        <Spin size="large" />
      </div>
    );
  }

  const getAuctionStatus = () => {
    const startTime = new Date(detailCar.start_time);
    const endTime = new Date(detailCar.end_time);
    if (currentTime < startTime) {
      const timeDiff = startTime - currentTime;
      return {
        status: "willStart",
        message: `Açık Artırma ${startTime.toLocaleString()} tarihinde başlayacak.`,
        countdown: timeDiff,
      };
    } else if (currentTime >= startTime && currentTime <= endTime) {
      const timeDiff = endTime - currentTime;
      return {
        status: "active",
        message: "Kalan Teklif Süresi",
        countdown: timeDiff,
      };
    } else {
      return {
        status: "ended",
        message: "İhalalar kapandı",
        countdown: 0,
      };
    }
  };

  const auctionStatus = getAuctionStatus();

  // const formatCurrency = (value) => {
  //   return new Intl.NumberFormat('tr-TR').format(value) + ' TL';
  // };
  const images = detailCar?.car?.images || [];

  return (
    <div className="container lg:mx-auto">
      <div className="grid grid-cols-12 lg:gap-x-[40px]">
        {/* Mobil İçin Kalan Teklif Süresi */}
        <div className="col-span-12 lg:hidden">
          <div className={`bg-[#FFEB00] py-[12px] text-center px-[12px] rounded-[8px] text-[#09090B] w-[340px] lg:w-[345px] mb-[12px] font-medium text-sm flex flex-col items-center justify-center`}>
            {auctionStatus.status === "willStart" && (
              <>
                <span className="font-bold text-[#09090B] text-[16px]">{formatTime(auctionStatus.countdown)}</span>
                <span className="mt-[8px] text-sm">{auctionStatus.message}</span>
              </>
            )}
            {auctionStatus.status === "active" && (
              <>
                <span className="font-bold text-[#09090B] text-[16px]">{formatTime(auctionStatus.countdown)}</span>
                <span className="mt-[8px] text-sm">{auctionStatus.message}</span>
              </>
            )}
            {auctionStatus.status === "ended" && (
              <span className="font-bold text-[#09090B] text-[16px]">İhaleler kapandı</span>
            )}
          </div>
        </div>

        {/* Mobil İçin Açık Artırma Detayı */}
        <div className="col-span-12 lg:hidden">
          <div className="w-[340px] lg:w-full">
            <div className="flex justify-between items-start w-[340px] lg:w-full">
              <div className="flex flex-col">
                <img
                  src={`${imageBaseUrl}${detailCar.car.car_brand.img_path}`}
                  alt={detailCar.car.car_brand.name}
                  className="w-[32px] h-[32px]"
                />
                <h2 className="font-semibold text-[#09090B] text-lg lg:mt-[8px]">{detailCar.title}</h2>
                <h2 className="mt-[4px] mb-[10px] lg:mt-[8px] lg:mb-[20px] text-sm font-light text-[#09090B]">
                  {detailCar.car.description}
                </h2>
                <div className="flex gap-1 lg:gap-5 items-center lg:mt-[16px]">
                  <div className="flex items-center gap-2">
                    <span className="text-sm text-[#71717A]">{detailCar.car.mileage} KM</span>
                  </div>
                  <div className="flex items-center gap-2">
                    <img src={`${imageBaseUrl}${detailCar.car.car_fuel_type.img_path}`} alt="fuel-icon" className="w-[20px] h-[20px]" />
                    <span className="text-sm text-[#71717A]">{detailCar.car.car_fuel_type.name}</span>
                  </div>
                  <div className="flex items-center gap-2">
                    <img src={`${imageBaseUrl}${detailCar.car.car_gear_type.img_path}`} alt="gearbox-icon" className="w-[20px] h-[20px]" />
                    <span className="text-sm text-[#71717A]">{detailCar.car.car_gear_type.name}</span>
                  </div>
                  <div className="flex items-center gap-2">
                    <img src="/assets/icons/calendar-colourless.svg" alt="calendar-icon" className="w-[20px] h-[20px]" />
                    <span className="text-sm text-[#71717A]">{detailCar.car.year} Model</span>
                  </div>
                </div>
              </div>
              <div className="hidden lg:block">
                <div className="bg-[#FFEB00] py-[12px] text-center lg:py-[6px] px-[12px] rounded-[8px] text-[#09090B] w-full lg:h-[97px] font-medium text-sm flex flex-col items-center justify-center lg:w-[175px]">
                  <span className="font-bold text-[#09090B] text-[16px]">18 : 45 : 09</span>
                  <span className="mt-[8px] text-sm">Kalan Teklif Süresi</span>
                </div>
              </div>
            </div>
            <div className="border-2 dashed-border w-[340px] lg:w-full h-px my-[20px] lg:my-[40px]"></div>
            <div className="flex flex-col lg:flex-row lg:justify-between lg:items-end">
              <div className="flex flex-col">
                <div className="flex items-center gap-2">
                  <span className="text-sm font-light text-[#71717A]">Açılış Fiyatı</span>
                  <span className="text-sm font-medium text-[#09090B]">
                    {formatCurrency(detailCar.starting_price)}
                  </span>
                </div>
                <AuctionBidLast auctionId={id} detailCar={detailCar} />
              </div>
              <div className="flex items-center gap-2 mt-3 lg:mt-0 w-[340px] lg:w-auto">
                <button className="bg-zinc-100 text-center py-[12px] lg:py-[6px] px-[12px] lg:px-[24px] lg:h-[56px] rounded-[8px] text-[#09090B] w-full lg:w-auto font-medium text-sm flex justify-center items-center">
                  <span>Otomatik Teklif</span>
                </button>
                <AuctionBidAdd detailCar={detailCar} auctionId={id} />

              </div>
            </div>
            <div className="flex items-center gap-2 mt-3 w-[340px] lg:w-full lg:gap-[16px] lg:mt-[29px]">
              <button className="bg-zinc-100 text-center py-[12px] lg:py-[20px] px-[12px] lg:px-[24px] lg:h-[92px] rounded-[8px] text-[#09090B] w-full lg:w-full font-medium text-sm flex items-center gap-2.5">
                <img src="/assets/icons/valor-detail.svg" alt="valor-icon" className="w-[20px] h-[20px] lg:w-auto lg:h-auto" />
                <div className="flex flex-col gap-1">
                  <span className="text-[#09090B] font-semibold ">Valör Desteği</span>
                  <Link to="/" className="self-start font-light text-sm underline">
                    İncele
                  </Link>
                </div>
              </button>
              <button className="bg-[#FFEB00] py-[12px] text-center lg:py-[20px] px-[12px] lg:px-[24px] lg:h-[92px] rounded-[8px] text-[#09090B] w-full lg:w-full font-medium text-sm flex items-center gap-2.5">
                <img src="/assets/icons/solid-wallet-detail.svg" alt="wallet-icon" className="w-[20px] h-[20px] lg:w-auto lg:h-auto" />
                <div className="flex flex-col gap-1">
                  <span className="text-[#09090B] font-semibold ">Kredi Desteği</span>
                  <Link to="/" className="self-start font-light text-sm underline">
                    İncele
                  </Link>
                </div>
              </button>
            </div>
          </div>
        </div>

        <div className="col-span-12 lg:col-span-5 h-96 mt-5 lg:mt-0 w-[340px] lg:w-auto hidden">
          {/* Ana Resim Swiper */}
          <Swiper
            style={{
              "--swiper-navigation-color": "#fff",
              "--swiper-pagination-color": "#fff",
            }}
            spaceBetween={10}
            navigation={true}
            thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
            modules={[FreeMode, Navigation, Thumbs]}
            className="mySwiper2"
          >
            {detailCar.car.images.map((image, index) => (
              <SwiperSlide key={index}>
                <img
                  src={`${imageBaseUrl}${image.img_path}`}
                  className="object-cover w-[365px] lg:w-[100%] h-[320px] rounded-[16px] cursor-pointer"
                  alt={`Araba Görseli ${index + 1}`}
                  onClick={() => openModal(index)}
                />
              </SwiperSlide>
            ))}
          </Swiper>

          {/* Thumbnail Swiper */}
          <Swiper
            onSwiper={setThumbsSwiper}
            spaceBetween={10}
            slidesPerView={4}
            freeMode={true}
            watchSlidesProgress={true}
            modules={[FreeMode, Navigation, Thumbs]}
            className="mySwiper mt-2.5"
          >
            {detailCar.car.images.map((image, index) => (
              <SwiperSlide key={index}>
                <img
                  src={`${imageBaseUrl}${image.img_path}`}
                  className="object-cover w-[100%] h-[96px]"
                  alt={`Küçük Resim ${index + 1}`}
                />
              </SwiperSlide>
            ))}
          </Swiper>

          {/* Modal Bileşeni */}
          {isModalOpen && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm z-50" onClick={closeModal}>
              <div className="relative w-full max-w-3xl p-4" onClick={(e) => e.stopPropagation()}>
                <button className="absolute top-5  font-bold right-10 text-white text-3xl z-40  hover:text-4xl transition-all duration-300 ease-in-out" onClick={closeModal}>✕</button>
                <Swiper
                  initialSlide={initialSlideIndex} // Başlangıçta tıklanan resmi gösterir
                  navigation={true}
                  spaceBetween={10}
                  modules={[Navigation]}
                  style={{
                    "--swiper-navigation-color": "#fff", // Modal içinde de okların rengini beyaz yapar
                  }}
                  className="mySwiper"
                >
                  {detailCar.car.images.map((image, index) => (
                    <SwiperSlide key={index}>
                      <img
                        src={`${imageBaseUrl}${image.img_path}`}
                        className="object-cover w-full max-h-[90vh] rounded-[16px]"
                        alt={`Modal Resmi ${index + 1}`}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          )}
        </div>
        <div className="col-span-12 lg:col-span-5 mt-5 lg:mt-0 w-[340px] lg:w-auto">
          {selectedImage && (
            <Image.PreviewGroup
              preview={{
                onChange: (current) => {
                  setSelectedImage(images[current - 1]);
                },
              }}
            >
              {/* Selected Image Display */}
              <Image
                src={`${imageBaseUrl}${selectedImage?.img_path}`}
                alt={`Selected Car Image`}
                className="mb-4 object-cover w-[365px] lg:w-[100%] h-[320px] rounded-[16px]"
              />

              {/* Thumbnail Row */}
              <div className="grid grid-cols-4 gap-2">
                {images.map((image, index) => (
                  <Image
                    key={index}
                    height={100}
                    src={`${imageBaseUrl}${image.img_path}`}
                    className={`cursor-pointer h-[120px] object-contain rounded-md  ${selectedImage === image ? 'border-2 border-blue-500' : ''
                      }`}
                    alt={`Thumbnail ${index + 1}`}
                    onClick={() => setSelectedImage(image)}
                  />
                ))}
              </div>
            </Image.PreviewGroup>
          )}
        </div>
        {/* Detay İçeriği (Geniş Ekran) */}
        <div className="hidden lg:grid lg:col-span-7">
          <div className="w-full">
            <div className="flex justify-between items-start w-full">
              <div className="flex flex-col">
                <img
                  src={`${imageBaseUrl}${detailCar.car.car_brand.img_path}`}
                  alt={detailCar.car.car_brand.name}
                  className="w-[20px] h-[20px] lg:w-[32px] lg:h-[32px]"
                />
                <h2 className="font-semibold text-[#09090B] text-lg lg:mt-[8px]">{detailCar.title}</h2>
                <h2 className="mt-[4px] mb-[10px] lg:mt-[8px] lg:mb-[20px] text-sm font-light text-[#09090B]">
                  {detailCar.car.description}
                </h2>
                <div className="flex gap-1 lg:gap-5 items-center lg:mt-[16px]">
                  <div className="flex items-center gap-2">
                    <span className="text-sm text-[#71717A]">{detailCar.car.mileage} KM</span>
                  </div>
                  <div className="flex items-center gap-2">
                    <img src={`${imageBaseUrl}${detailCar.car.car_fuel_type.img_path}`} alt="fuel-icon" className="w-[20px] h-[20px]" />
                    <span className="text-sm text-[#71717A]">{detailCar.car.car_fuel_type.name}</span>
                  </div>
                  <div className="flex items-center gap-2">
                    <img src={`${imageBaseUrl}${detailCar.car.car_gear_type.img_path}`} alt="gearbox-icon" className="w-[20px] h-[20px]" />
                    <span className="text-sm text-[#71717A]">{detailCar.car.car_gear_type.name}</span>
                  </div>
                  <div className="flex items-center gap-2">
                    <img src="/assets/icons/calendar-colourless.svg" alt="calendar-icon" className="w-[20px] h-[20px]" />
                    <span className="text-sm text-[#71717A]">{detailCar.car.year} Model</span>
                  </div>
                </div>
              </div>
              <div className="hidden lg:block">
                <div className="bg-[#FFEB00] py-[12px] text-center lg:py-[6px] px-[12px] rounded-[8px] text-[#09090B] w-full lg:h-[97px] font-medium text-sm flex flex-col items-center justify-center lg:w-[175px]">
                  {auctionStatus.status === "willStart" && (
                    <>
                      <span className="font-bold text-[#09090B] text-[16px]">{formatTime(auctionStatus.countdown)}</span>
                      <span className="mt-[8px] text-sm">{auctionStatus.message}</span>
                    </>
                  )}
                  {auctionStatus.status === "active" && (
                    <>
                      <span className="font-bold text-[#09090B] text-[16px]">{formatTime(auctionStatus.countdown)}</span>
                      <span className="mt-[8px] text-sm">{auctionStatus.message}</span>
                    </>
                  )}
                  {auctionStatus.status === "ended" && (
                    <span className="font-bold text-[#09090B] text-[16px]">İhaleler kapandı</span>
                  )}
                </div>
              </div>
            </div>
            <div className="border-2 dashed-border w-full h-px my-[40px]"></div>
            <div className="flex flex-col lg:flex-row lg:justify-between lg:items-end">
              <div className="flex flex-col">
                <div className="flex items-center gap-2">
                  <span className="text-sm font-light text-[#71717A]">Açılış Fiyatı</span>
                  <span className="text-sm font-medium text-[#09090B]">   {formatCurrency(detailCar.starting_price)}</span>
                </div>
                <AuctionBidLast auctionId={id} detailCar={detailCar} />
              </div>
              <div className="flex items-center gap-2 mt-3 lg:mt-0">
                <button className="bg-zinc-100 text-center py-[12px] lg:py-[6px] px-[12px] lg:px-[24px] lg:h-[56px] rounded-[8px] text-[#09090B] w-full lg:w-auto font-medium text-sm flex justify-center items-center">
                  <span>Otomatik Teklif</span>
                </button>
                <AuctionBidAdd detailCar={detailCar} auctionId={id} />
              </div>
            </div>
            <div className="flex items-center gap-2 mt-3 lg:mt-0 w-full lg:gap-[16px] lg:mt-[29px]">
              <button className="bg-zinc-100 text-center py-[12px] lg:py-[20px] px-[12px] lg:px-[24px] lg:h-[92px] rounded-[8px] text-[#09090B] w-full lg:w-full font-medium text-sm flex items-center gap-2.5">
                <img src="/assets/icons/valor-detail.svg" alt="valor-icon" />
                <div className="flex flex-col gap-1">
                  <span className="text-[#09090B] font-semibold ">Valör Desteği</span>
                  <Link to="/" className="self-start font-light text-sm underline">
                    İncele
                  </Link>
                </div>
              </button>
              <button className="bg-[#FFEB00] py-[12px] text-center lg:py-[20px] px-[12px] lg:px-[24px] lg:h-[92px] rounded-[8px] text-[#09090B] w-full lg:w-full font-medium text-sm flex items-center gap-2.5">
                <img src="/assets/icons/solid-wallet-detail.svg" alt="wallet-icon" />
                <div className="flex flex-col gap-1">
                  <span className="text-[#09090B] font-semibold ">Kredi Desteği</span>
                  <Link to="/" className="self-start font-light text-sm underline">
                    İncele
                  </Link>
                </div>
              </button>
            </div>
          </div>
          <div className="w-full mt-4">
            {/* CarStatusView Bileşenini Kullanın veya Alternatif İçerik Ekleyin */}
            <CarStatusView data={detailCar.car} expertiz_reports={detailCar?.car?.expertiz_reports} />
          </div>
        </div>

        {/* Araç Açıklaması ve Satıcı Bilgileri */}
        <div className="col-span-12 lg:col-span-5 mt-4  w-[340px] lg:w-auto">
          <div className="rounded-[16px] p-[24px] bg-[#F4F4F5] lg:h-[288px]">
            <div className="flex flex-col">
              <h6 className="text-[20px] font-semibold">Araç Açıklaması</h6>
              <div className="h-[114px] overflow-y-auto lg:mb-[24px] lg:mt-[12px] text-xs font-light">
                {detailCar.description}
              </div>
              <div className="flex items-center gap-3">
                <img
                  src="/assets/icons/tt-logo.svg"
                  alt="Satıcı"
                  className="w-[56px] h-[56px] rounded-full object-cover"
                />
                <div className="flex flex-col">
                  <h6 className="font-semibold">ttihale</h6>
                  <span className="text-sm font-light text-[#52525B]">Araç Satıcısı</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Araç Durumu */}

      </div>

      {/* Araç Özellikleri */}
      <div className="mt-[64px]">
        <h3 className="font-semibold text-[24px] text-center">Araç Özellikleri</h3>
        <div className="grid lg:grid-cols-3 gap-y-[10px] lg:gap-x-[48px] lg:gap-y-[24px] lg:mt-[32px]">
          {/* Dinamik olarak render edilen özellikler */}
          <div className="flex items-center justify-between rounded-[12px] bg-[#F4F4F5] py-[8px] px-[16px]">
            <span className="text-sm text-[#27272A]">Araç Kasası</span>
            <span className="text-sm font-semibold text-[#27272A]">{detailCar.car?.car_body_type?.name}</span>
          </div>
          <div className="flex items-center justify-between border rounded-[12px] bg-[#fff] py-[8px] px-[16px]">
            <span className="text-sm text-[#27272A]">Plaka</span>
            <span className="text-sm font-semibold text-[#27272A]">{detailCar.car.plate}</span>
          </div>
          <div className="flex items-center justify-between rounded-[12px] bg-[#F4F4F5] py-[8px] px-[16px]">
            <span className="text-sm text-[#27272A]">Araç Rengi</span>
            <span className="text-sm font-semibold text-[#27272A]">{detailCar.car.car_color.name}</span>
          </div>
          <div className="flex items-center justify-between border rounded-[12px] bg-[#fff] py-[8px] px-[16px]">
            <span className="text-sm text-[#27272A]">Yakıt</span>
            <span className="text-sm font-semibold text-[#27272A]">{detailCar.car.car_fuel_type.name}</span>
          </div>
          <div className="flex items-center justify-between rounded-[12px] bg-[#F4F4F5] py-[8px] px-[16px]">
            <span className="text-sm text-[#27272A]">Şehir</span>
            <span className="text-sm font-semibold text-[#27272A]">{detailCar?.car?.city?.name}</span>
          </div>
          <div className="flex items-center justify-between border rounded-[12px] bg-[#fff] py-[8px] px-[16px]">
            <span className="text-sm text-[#27272A]">KM</span>
            <span className="text-sm font-semibold text-[#27272A]">{detailCar.car.mileage}</span>
          </div>
          <div className="flex items-center justify-between rounded-[12px] bg-[#F4F4F5] py-[8px] px-[16px]">
            <span className="text-sm text-[#27272A]">Vites Tipi</span>
            <span className="text-sm font-semibold text-[#27272A]">{detailCar.car.car_gear_type.name}</span>
          </div>
          <div className="flex items-center justify-between border rounded-[12px] bg-[#fff] py-[8px] px-[16px]">
            <span className="text-sm text-[#27272A]">Model Yılı</span>
            <span className="text-sm font-semibold text-[#27272A]">{detailCar.car.year}</span>
          </div>
          <div className="flex items-center justify-between rounded-[12px] bg-[#F4F4F5] py-[8px] px-[16px]">
            <span className="text-sm text-[#27272A]">Motor Gücü</span>
            <span className="text-sm font-semibold text-[#27272A]">{detailCar.car.engine_power} hp</span>
          </div>
          <div className="flex items-center justify-between border rounded-[12px] bg-[#fff] py-[8px] px-[16px]">
            <span className="text-sm text-[#27272A]">Marka</span>
            <span className="text-sm font-semibold text-[#27272A]">{detailCar.car.car_brand.name}</span>
          </div>
          <div className="flex items-center justify-between rounded-[12px] bg-[#F4F4F5] py-[8px] px-[16px]">
            <span className="text-sm text-[#27272A]">Model</span>
            <span className="text-sm font-semibold text-[#27272A]">{detailCar.car.car_model.name}</span>
          </div>
          <div className="flex items-center justify-between border rounded-[12px] bg-[#fff] py-[8px] px-[16px]">
            <span className="text-sm text-[#27272A]">Motor Hacmi</span>
            <span className="text-sm font-semibold text-[#27272A]">{detailCar.car.engine_size} cc</span>
          </div>
          <div className="flex items-center justify-between rounded-[12px] bg-[#F4F4F5] py-[8px] px-[16px]">
            <span className="text-sm text-[#27272A]">Garanti</span>
            <span className="text-sm font-semibold text-[#27272A]">{detailCar.car.warranty_status}</span>
          </div>
          <div className="flex items-center justify-between border rounded-[12px] bg-[#fff] py-[8px] px-[16px]">
            <span className="text-sm text-[#27272A]">Ağır Hasar Kaydı</span>
            <span className="text-sm font-semibold text-[#27272A]">{detailCar.car.damage_status}</span>
          </div>
          <div className="flex items-center justify-between rounded-[12px] bg-[#F4F4F5] py-[8px] px-[16px]">
            <span className="text-sm text-[#27272A]">Plaka (Ülke Uyruğu)</span>
            <span className="text-sm font-semibold text-[#27272A]">{detailCar.car.car_plate_locale_id === 1 ? "Türkiye" : "Diğer"}</span>
          </div>
        </div>
      </div>
      {/* Reklam Bannerı */}
      {valorContent && (
        <div className="mt-[64px]">
          <img
            src={`${cmsImageUrl}${valorContent?.url}`} // Artık doğrudan "url" öğesine erişiyoruz
            alt="Reklam"
            className="w-full"
          />
        </div>
      )}

      {/* FeatureGroupView Bileşeni */}
      <FeatureGroupView selectedCarFeatures={featuresId} />
    </div>
  );
};

export default AuctionsDetail;
