import axios from "axios";
import { message } from "antd"; 
import { store } from '../store';
const AxiosController = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
  },
});

AxiosController.interceptors.request.use(
  (config) => {
    const state = store.getState();
    const token = state.auth.token; // Redux'tan token alıyoruz
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

AxiosController.interceptors.response.use(
  (response) => response.data,
  (error) => {
    if (error.response) {
      const status = error.response.status;
      const data = error.response.data;

      if (status === 401) {
        // Yetkisiz erişim, kullanıcıyı çıkış yapıyoruz
        // store.dispatch(logout());
      } else {
        // Hata mesajını 'detail' alanından alıyoruz
        const errorMessage = data.detail || 'Bir hata oluştu';

        // Hata mesajını global olarak gösteriyoruz
        message.error(errorMessage);
      }
    } else {
      // Ağ hatası veya yanıt olmayan diğer hatalar
      message.error('Ağ hatası, lütfen daha sonra tekrar deneyiniz.');
    }

    return Promise.reject(error);
  }
);

export const baseUrl = process.env.REACT_APP_API_URL;
export const imageBaseUrl = process.env.REACT_APP_IMAGE_API_URL;

export const socketbaseUrl = process.env.REACT_APP_SOCKET_API_URL;

export default AxiosController;
