import { Routes, Route } from 'react-router-dom';
import Home from './views/Home/Home'
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import About from './views/About/About';
import Contact from './views/Contact/Contact';
import Auctions from './views/Auctions/Auctions';
import Profile from './views/Profile/Profile';
import Blog from './views/Blog/Blog';
import AuctionsDetail from './views/Auctions/AuctionsDetail';
import ReceivedDetail from './views/Profile/ReceivedDetail';
import BlogDetail from './views/BlogDetail/BlogDetail';
import ScrollToTop from './hooks/ScrollToTop';
import Privacy from './views/Sozlesme/Privacy';

function App() {




  return (
    <div>
      <ScrollToTop />
      <Navbar />
      <main className='py-8 px-6'>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/auctions" element={<Auctions />} />
          <Route path="/auctions/:id" element={<AuctionsDetail />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:slug" element={<BlogDetail />} />
          <Route path="/received/:id" element={<ReceivedDetail />} />
          <Route path="/sozlesme/:id" element={<Privacy />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
}

export default App;
